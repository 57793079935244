<template>
  <div class="green-border-box in-padd white-bg">
    <div class="row">
      <div class="col-xs-12 col-md-4">
        <input
          class="search-box"
          type="search"
          q="store"
          v-model="storeSearchQuery"
          placeholder="Search store"
        >
      </div>
      <div class="col-xs-12 col-md-8 sort-control">
        <div class="filters">
          Sort By
          <select v-model="sort">
            <option value="id-DESC" selected>Created on DESC</option>
            <option value="id-ASC">created On ASC</option>
            <option value="name-ASC">A-Z</option>
            <option value="name-DESC">Z-A</option>
            <option value="deleted_at-DESC">Deleted Stores</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-xs-12 col-sm-6 item-list"
        v-for="store in stores"
        :key="store.id"
        v-if="stores.length>0"
      >
        <StoreItem :store="store" @getStoreDetails="getStoreDetails"/>
      </div>
    </div>
    <h2
      v-if="stores.length==0 && !loadingStores"
      style="text-align:center;"
    >No Store Found in system!</h2>
    <h2 v-if="loadingStores" style="text-align:center;">Loading Stores!</h2>
    <div class="text-center" v-if="hasMoreStores && !loadingStores" @click="getStores">
      <button class="btn-positive">Load more</button>
    </div>
    <StorePopup
      @refreshStoreList="reFilter"
      :editStoreFlag="false"
      popupId="storepopup"
      :closeStoreEditPopup="closeStoreEditPopup"
    />
    <StorePopup
      @refreshStoreList="reFilter"
      :storeDetails="storeDetails"
      :editStoreFlag="true"
      popupId="editstorepopup"
      :closeStoreEditPopup="closeStoreEditPopup"
    />
    <RestoreStorePopup
      @StoreRestored="reFilter"
      :storeDetails="storeDetails"
      popupId="restorestorepopup"
    />
    <DeleteStorePopup
      :stores="stores"
      @StoreDeleted="storeDeleted"
      :storeDetails="storeDetails"
      popupId="deletestorepopup"
    />
  </div>
</template>

<script>
import StoreItem from '@/components/SAdmin/StoreItem.vue'
import StorePopup from '@/components/SAdmin/StorePopup.vue'
import RestoreStorePopup from '@/components/SAdmin/RestoreStorePopup'
import DeleteStorePopup from '@/components/SAdmin/DeleteStorePopup'
export default {
  name: 'Stores',
  data () {
    return {
      timer: 0,
      loadingStores: true,
      hasMoreStores: false,
      editStoreFlag: false,
      storeSearchQuery: '',
      city: '',
      zipCode: '',
      sort: 'id-DESC',
      rowsOffset: 0,
      rowsLimitPerRequest: 100,
      stores: [],
      storeDetails: {
        city: '',
        state: '',
        zip_code: '',
        longitude: '',
        latitude: '',
        image: '',
        address: '',
        name: ''
      },
      closeStoreEditPopup: false
    }
  },
  components: {
    StoreItem,
    StorePopup,
    RestoreStorePopup,
    DeleteStorePopup
  },
  mounted () {
    this.getStores()
  },
  methods: {
    getStores () {
      this.loadingStores = true
      let q = { query: this.storeSearchQuery, sort: this.sort, city: this.city, zipCode: this.zipCode, limit: this.rowsLimitPerRequest, offset: this.rowsOffset, with: 'trashed' }
      this.$http
        .get('stores', { params: q })
        .then(
          function (response) {
            if (this.rowsLimitPerRequest === q.limit && this.rowsOffset === q.offset && this.storeSearchQuery === q.query) {
              this.rowsOffset += this.rowsLimitPerRequest
              this.stores.push(...response.body.data)
              if (this.stores.length === response.body.count) {
                this.hasMoreStores = false
              } else {
                this.hasMoreStores = true
              }
              this.loadingStores = false
            }
          }
        )
    },
    storeDeleted () {
      this.closeStoreEditPopup = true
      this.reFilter()
    },
    reFilter () {
      this.stores = []
      this.rowsOffset = 0
      this.getStores()
    },
    getStoreDetails (storeId) {
      let storeDetails = this.stores.filter(obj => {
        return obj.id === storeId
      })
      this.editStoreFlag = true
      this.storeDetails = storeDetails[0]
    }
  },

  watch: {
    sort () {
      this.loadingStores = true
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    },
    storeSearchQuery () {
      this.loadingStores = true
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    },
    city () {
      this.loadingStores = true
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    },
    zipCode () {
      this.loadingStores = true
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    }
  }
}
</script>

<style scoped>
.sort-control {
  text-align: right;
  padding: 6px 15px;
  margin-bottom: 20px;
}

.sort-control .btn-select {
  margin-bottom: 7px;
}

.filter-control {
  margin-right: 30px;
}

.filters {
  display: inline-block;
  width: auto;
  vertical-align: text-top;
}

@media only screen and (max-width: 991px) {
  .sort-control {
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .filter-control {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .filter-control {
    margin-right: 0;
    width: 100%;
    margin-bottom: 8px;
    display: inline-block;
  }

  .item-list >>> .outer-box span {
    white-space: normal;
    word-wrap: break-word;
  }
}
</style>
