<template>
    <div  :class="{grayed:store.deleted_at} " class="green-border-box outer-box">
        <div class="inner-box">
            <img class="grey-logo-circle" :src="store.image+'?w=150'" @error="imageLoadOnError"/>
            <span class="title" >{{store.name}}</span><br />
            <div class="text-center">
              <span  class="">{{store.deleted_at?'Deleted On':'Created On:'}}  {{store.date}} {{store.time}}</span><br />
              <button
                type="button"
                class="btn-positive btn-edit"
                data-toggle="modal"
                data-target="#restorestorepopup"
                @click="openRestoreStorePopup(store.id)"
                v-if="store.deleted_at"
              >Restore</button>
              <button v-else type="button" class="btn-positive btn-edit" data-toggle="modal" data-target="#editstorepopup" @click="openEditStorePopup(store.id)">EDIT</button>
            </div>
        </div>
    </div>
</template>

<script >
export default {
  props: {
    store: Object
  },
  methods: {
    imageLoadOnError () {
      this.store.image = '/img/default_store_image.png'
    },
    openEditStorePopup (storeId) {
      this.$emit('getStoreDetails', storeId)
    },
    openRestoreStorePopup (storeId) {
      this.$emit('getStoreDetails', storeId)
    }
  }
}
</script>
<style scoped>
.outer-box {
  padding: 15px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 25px;
}

.inner-box {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height:60px;
}

.outer-box .title,
.outer-box .description {
  padding: 0 0 0 80px;
  white-space: nowrap;
  width: 100%;
  line-height: 30px;
  box-sizing: border-box;
  overflow: visible;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.outer-box .title {
  font-weight: 500;
  font-size: 22px;
  color: #1f1f1f;
  padding-right: 72px;
}

.outer-box .description {
  font-size: 17px;
  color: black;
}

.btn-edit {
  font-size: 14px;
  line-height: 18px;
  padding: 3px 15px;
  font-weight: 500;
  color: black;
  position: absolute;
  right: 0;
  top: 0;
}

.allocated {
  font-weight: 500;
  color: #782b54;
  font-size: 15px;
  margin-top: 5px;
  display: inline-block;
}

.allocated-alert {
  color: #e64733;
}

@media only screen and (max-width: 991px) {
  .outer-box .title {
    height: 60px;
    white-space: normal;
    text-align: left;
    padding-right: 0;
  }

  .btn-edit {
    float: right;
    margin-left: 10px;
    position: static;
  }

  .outer-box .description {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .outer-box {
    text-align: left;
  }

  .outer-box .title {
    padding-left: 70px;
  }

  .btn-edit {
    position: static;
  }
}
.grayed {
  background: repeating-linear-gradient(
    45deg,
    #fff,
    #fff 20px,
    #ddd 0,
    #ddd 40px
  );
}
</style>
