<template>
  <div
    :id="popupId"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2>
            <img src="/img/Alerts/plusbig_icon.png"> Store
          </h2>
          <button
            ref="popupCloseButton"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form v-on:submit.prevent="addStore" autocomplete="off" data-vv-scope="add-store">
            <input
              type="text"
              name="name"
              v-model="store.name"
              placeholder="Store Name"
              v-validate="'required'"
              :class="{'input': true, 'is-danger': errors.has('add-store.name') }"
            >
            <!-- <span class="error" v-show="errors.has('name')">{{ errors.first('name') }}</span> -->
            <div class="row">
              <div class="col-sm-offset-3 col-sm-6 text-center">
                <h4>Store Image</h4>
                <croppa
                  v-model="myCroppa"
                  placeholder="Choose a Store Image"
                  initial-size="contain"
                  :placeholder-font-size="16"
                  :show-remove-button="false"
                  :initial-image="currentImage"
                ></croppa>
                <button
                  v-if="myCroppa.hasImage && myCroppa.hasImage()"
                  class="upload-remove"
                  type="button"
                  @click="myCroppa.remove()"
                ></button>
                <input type="hidden" name="image" v-model="store.image" v-validate="'required'">
                <br>
                <span
                  class="error-msg"
                  v-show="!(myCroppa.hasImage && myCroppa.hasImage()) && errors.has('add-store.image')"
                >{{ errors.first('add-store.image') }}</span>
                <br>
              </div>
              <!-- <div class="col-sm-6" v-if="currentImage!=''">
                                <h4>Current Image</h4>
                                <img :src="currentImage" class="img-responsive" />
              </div>-->
            </div>
            <!-- <div class="upload-control">
                            <span class="btn-positive uploadButton">
                                Select Image
                                <input type="file" />
                            </span>
            </div>-->
            <div class="row">
              <div class="col-xs-12 col-sm-6" v-if="editStoreFlag">
                <button
                  type="button"
                  class="btn-lg-grey"
                  data-toggle="modal"
                  data-target="#deletestorepopup"
                  @click="getStore"
                >Delete STORE</button>
              </div>
              <div class="col-xs-12" :class="{ 'col-sm-6': editStoreFlag }">
                <button
                  type="submit"
                  class="btn-lg-green"
                  :disabled="submittingForm"
                >{{ editStoreFlag ? 'UPDATE' : 'ADD' }} STORE</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StorePopup',
  props: ['popupId', 'editStoreFlag', 'storeDetails', 'closeStoreEditPopup'],
  data () {
    return {
      store: {
        name: '',
        image: ''
      },
      address: '',
      myCroppa: {},
      imageSelected: false,
      imageUrl: '',
      submittingForm: false,
      currentImage: ''
    }
  },
  methods: {
    addStore () {
      this.generateImage()
      this.$validator.validateAll('add-store').then((result) => {
        if (result) {
          this.submittingForm = true
          if (this.editStoreFlag) {
            this.$http
              .put('stores/' + this.store.id, this.store, {
                headers: {
                  'user-type': 'sadmin'
                }
              })
              .then(
                function (response) {
                  this.$emit('refreshStoreList')
                  this.$refs.popupCloseButton.click()
                  this.submittingForm = false
                  this.resetForm()
                },
                function (response) {
                  this.errorMessage = response.body.message
                  this.submittingForm = false
                }
              )
          } else {
            this.$http
              .post('stores', this.store, {
                headers: {
                  'user-type': 'sadmin'
                }
              })
              .then(
                function (response) {
                  this.$emit('refreshStoreList')
                  this.$refs.popupCloseButton.click()
                  this.submittingForm = false
                  this.resetForm()
                },
                function (response) {
                  this.errorMessage = response.body.message
                  this.submittingForm = false
                }
              )
          }
        } else {
          if (this.errors.has('add-store.address')) {
            this.$notify({ type: 'error', text: 'Please select the correct address from the list' })
          }
        }
      })
    },

    deleteStore () {
      let confirmation = confirm('Are you sure you want to delete this store?')
      if (confirmation) {
        this.$http
          .delete('stores/' + this.store.id, {
            headers: {
              'user-type': 'sadmin'
            }
          })
          .then(
            function (response) {
              this.$emit('refreshStoreList')
              this.$refs.popupCloseButton.click()
              this.submittingForm = false
            },
            function (response) {
              this.errorMessage = response.body.message
              this.submittingForm = false
            }
          )
      }
    },
    resetForm () {
      this.myCroppa.remove()
      let myCroppa = this.myCroppa
      Object.assign(this.$data, this.$options.data.apply(this))
      this.myCroppa = myCroppa
      this.$nextTick()
        .then(() => {
          this.errors.clear('add-store')
        })
    },
    getAddressData (addressData, placeResultData, id) {
      this.address = placeResultData.formatted_address
      this.store.address = placeResultData.formatted_address
      this.store.city = addressData.locality
      for (let i = placeResultData.address_components.length - 1; i > 0; i--) {
        if (placeResultData.address_components[i].types[0] === 'administrative_area_level_1') {
          this.store.state = placeResultData.address_components[i].long_name
        }
      }
      this.store.zip_code = addressData.postal_code
      this.store.latitude = addressData.latitude
      this.store.longitude = addressData.longitude
    },
    generateImage () {
      let url = this.myCroppa.generateDataUrl()
      if (!url) {
        return
      }

      this.store.image = url
    },
    CropEnable () {
      this.store.image = ''
      this.imageSelected = true
    },
    cropDisable () {
      this.store.image = ''
      this.imageSelected = false
    },
    clear () {
      if (this.address !== this.store.address) this.store.address = ''
    },
    getStore () {
      this.$emit('getStoreDetails', this.store)
    }
  },
  watch: {
    storeDetails () {
      this.store = Object.assign({}, this.storeDetails)
      this.myCroppa.refresh()
      this.currentImage = this.store.image + '?w=300'
      this.store.image = ''
    },
    closeStoreEditPopup () {
      if (this.closeStoreEditPopup) {
        this.$refs.popupCloseButton.click()
      }
    }
  }
}
</script>
<style scoped>
.btn-negative {
  font-size: 15px;
  font-weight: 400;
  margin-top: 0;
}

.image-upload {
  display: inline-block;
  width: auto;
  text-align: center;
}
</style>
