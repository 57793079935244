var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._m(0),
            _c("button", {
              ref: "popupCloseButton",
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close"
              }
            })
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "form",
              {
                attrs: { autocomplete: "off", "data-vv-scope": "add-store" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.addStore($event)
                  }
                }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.store.name,
                      expression: "store.name"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  class: {
                    input: true,
                    "is-danger": _vm.errors.has("add-store.name")
                  },
                  attrs: {
                    type: "text",
                    name: "name",
                    placeholder: "Store Name"
                  },
                  domProps: { value: _vm.store.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.store, "name", $event.target.value)
                    }
                  }
                }),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-offset-3 col-sm-6 text-center" },
                    [
                      _c("h4", [_vm._v("Store Image")]),
                      _c("croppa", {
                        attrs: {
                          placeholder: "Choose a Store Image",
                          "initial-size": "contain",
                          "placeholder-font-size": 16,
                          "show-remove-button": false,
                          "initial-image": _vm.currentImage
                        },
                        model: {
                          value: _vm.myCroppa,
                          callback: function($$v) {
                            _vm.myCroppa = $$v
                          },
                          expression: "myCroppa"
                        }
                      }),
                      _vm.myCroppa.hasImage && _vm.myCroppa.hasImage()
                        ? _c("button", {
                            staticClass: "upload-remove",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.myCroppa.remove()
                              }
                            }
                          })
                        : _vm._e(),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.store.image,
                            expression: "store.image"
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        attrs: { type: "hidden", name: "image" },
                        domProps: { value: _vm.store.image },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.store, "image", $event.target.value)
                          }
                        }
                      }),
                      _c("br"),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !(
                                  _vm.myCroppa.hasImage &&
                                  _vm.myCroppa.hasImage()
                                ) && _vm.errors.has("add-store.image"),
                              expression:
                                "!(myCroppa.hasImage && myCroppa.hasImage()) && errors.has('add-store.image')"
                            }
                          ],
                          staticClass: "error-msg"
                        },
                        [_vm._v(_vm._s(_vm.errors.first("add-store.image")))]
                      ),
                      _c("br")
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _vm.editStoreFlag
                    ? _c("div", { staticClass: "col-xs-12 col-sm-6" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn-lg-grey",
                            attrs: {
                              type: "button",
                              "data-toggle": "modal",
                              "data-target": "#deletestorepopup"
                            },
                            on: { click: _vm.getStore }
                          },
                          [_vm._v("Delete STORE")]
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "col-xs-12",
                      class: { "col-sm-6": _vm.editStoreFlag }
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn-lg-green",
                          attrs: {
                            type: "submit",
                            disabled: _vm.submittingForm
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.editStoreFlag ? "UPDATE" : "ADD") +
                              " STORE"
                          )
                        ]
                      )
                    ]
                  )
                ])
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _c("img", { attrs: { src: "/img/Alerts/plusbig_icon.png" } }),
      _vm._v(" Store\n        ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }